import React, {useEffect, useState} from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { HashLink } from 'react-router-hash-link';

const Final = () => {
    const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {

    function handleResize() {

      setWidth(window.innerWidth);

    }

    window.addEventListener("resize", handleResize);

  });
    return (
        <div class="page-projet">
            <h2>Jeu 3D sur l'environnement</h2>
            <div className="carousel">
            
            <Carousel showThumbs={width<1024?false:true}>

            <div>
                <img src="_assets/images/croquis_perso.jpg" alt="img1"/>
            </div>

            <div>
                <img src="_assets/images/croquis_ennemi.jpg" alt="img2"/>
            </div>
            <div>
                <img src="_assets/images/final_img2.png" alt="img3"/>
            </div>
            <div>
                <img src="_assets/images/final_img1.png" alt="img4"/>
            </div>
            <div>
                <img src="_assets/images/final_img3.png" alt="img5"/>
            </div>
            <div>
                <img src="_assets/images/final_img6.png" alt="img6"/>
            </div>
            <div>
                <img src="_assets/images/final_img4.png" alt="img7"/>
            </div>
            <div>
                <img src="_assets/images/final_img7.png" alt="img8"/>
            </div>
            <div>
                <img src="_assets/images/final_img5.png" alt="img9"/>
            </div>
            </Carousel>
            </div>
            <div class="info">

            <div class="desc">
                <h3>Réalisation du projet</h3>
                
                <p><b> Projet réalisé seul. J'ai tout modélisé, texturé et animé les objets 3D. J'ai créé les skybox, les shaders, les systèmes de particules et j'ai ajoutés les éléments de post-processing nécessaires. Aussi, j'ai créé la trame sonore pour le jeu et les éléments 2D pour le UI. J'ai aussi rédiger le code pour le jeu. </b>
                 Ce projet avait pour but de démontrer l'atteinte des compétences du programme de multimmédia tout en mettant en valeur mes propres compétences. Le projet devait aussi répondre à une problématique réelle qui, dans mon cas, était l'environnement. Le joueur peut se déplacer entre différentes dimensions et doit accomplir différents objectifs pour sauver la planète de la pollution.  </p>
            </div>

            <div class="logiciel">
                <h3>Logiciels utilisés</h3>
                <ul>
                    <li>Unity</li>
                    <li>Blender</li>
                    <li>Adobe Illustrator</li>
                    <li>Adobe Photoshop</li>
                    <li>BandLab</li>
                </ul>
            </div>

            <div class="retour">
                <HashLink to="/#h2_projet">Retour aux projets</HashLink>
            </div>
            </div>
        </div>
    )
}

export default Final