import React, {useEffect, useState} from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { HashLink } from 'react-router-hash-link';

const Vaisseau = () => {
    const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {

    function handleResize() {

      setWidth(window.innerWidth);

    }

    window.addEventListener("resize", handleResize);

  });
    return (
       
        <div class="page-projet" id="top">
            <h2>Jeu 2D à défilement vertical</h2>
            <div className="carousel">
            
            <Carousel showThumbs={width<1024?false:true}>
                <div>
                    <img src="_assets/images/assets_vaisseau.png" alt="img1"/>
                </div>
                <div>
                    <img src="_assets/images/vaisseau_img1.png" alt="img1"/>
                </div>
                <div>
                    <img src="_assets/images/vaisseau_img2.png" alt="img2"/>
                </div>
                <div>
                    <img src="_assets/images/vaisseau_img3.png" alt="img3"/>
                </div>
                <div>
                    <img src="_assets/images/vaisseau_img4.png" alt="img4"/>
                </div>
            </Carousel>
            </div>

            <div class="info">
            <div class="desc">
                <h3>Réalisation du projet</h3>
                <p><b>Projet réalisé seul dans lequel j'ai créé tous les éléments visuels présents dans le jeu et j'ai aussi rédigé le code.</b> Le but était de réaliser un jeu à défilement dans lequel le joueur devait éviter les obstacles et accumuler des points au fil de sa progression. 
                </p>
            </div>
            
            <div class="logiciel">
                <h3>Logiciels utilisés</h3>
                <ul>
                    <li>Unity</li>
                    <li>Adobe Illustrator</li>
                </ul>
            </div>
            <div class="retour">
                <HashLink to="/#h2_projet">Retour aux projets</HashLink>

            </div>
            </div>

        </div>
       
    )
}

export default Vaisseau
