import React, {useEffect, useState} from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { HashLink } from 'react-router-hash-link';

const Scene = () => {
    const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {

    function handleResize() {

      setWidth(window.innerWidth);

    }

    window.addEventListener("resize", handleResize);

  });
    return (
        <div class="page-projet">
            <h2>Scène Unity</h2>
            <div className="carousel">
            
            <Carousel showThumbs={width<1024?false:true}>
                <div>
                    <img src="_assets/images/scene_img_perso.png" alt="img_perso"/>
                </div>
                <div>
                    <img src="_assets/images/scene_img_house.png" alt="img_house"/>
                </div>
                <div>
                    <img src="_assets/images/scene_img_portal.png" alt="img_portal"/>
                </div>
                <div>
                    <img src="_assets/images/scene_img_tree.png" alt="img_tree"/>
                </div>
                <div>
                    <img src="_assets/images/scene_img1.png" alt="img1"/>
                </div>
                <div>
                    <img src="_assets/images/scene_img2.png" alt="img2"/>
                </div>
                <div>
                    <img src="_assets/images/scene_img3.png" alt="img3"/>
                </div>
                <div>
                    <img src="_assets/images/scene_img5.png" alt="img5"/>
                </div>
            </Carousel>
            </div>
            <div class="info">

            <div class="desc">
                <h3>Réalisation du projet</h3>
                <p><b> Projet réalisé seul dans lequel j'ai créé tous les éléments visuels présents dans la scène et j'ai aussi rédigé le code.</b> L'objectif de ce projet personnel était de développer une scène stylizée, de me pratiquer avec le logiciel de 3D Blender et de me familiariser avec le shadergraph de Unity.
                </p>
            </div>
            
            <div class="logiciel">
                <h3>Logiciels utilisés</h3>
                <ul>
                    <li>Unity</li>
                    <li>Blender</li>
                    <li>Adobe Photoshop</li>
                </ul>
            </div>
            
            <div class="retour">
                <HashLink to="/#h2_projet">Retour aux projets</HashLink>
            </div>
            </div>
        </div>
    )
}

export default Scene