import React from 'react'
import { HashLink } from 'react-router-hash-link';


const Accueil = () => {
    return (
        <main>

        <header>
          <h1 class="nom">RAPHAËL BLAIS</h1>
          <h1 class="profil">ARTISTE 2D | 3D</h1>
          
        </header>
            <section class="reels">
              <h2 id="h2_reel">Reel</h2>
              <div class="item">
                <div class="reel">
                <iframe width="960" height="540" src="https://www.youtube.com/embed/EIhvdHq-aV0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>
            </section>

            <section class="projets" >

              <h2 id="h2_projet">Projets</h2>

              <div class="item">

              <article class="zone">
                  <div class="projet final">
                  <HashLink to="/final#top">
                  <img src="_assets/images/final_img3.png" alt="img_final"/>
                  </HashLink>
                  </div>
                  <h3><HashLink to="/final#top">Jeu 3D sur l'environnement</HashLink></h3>
                </article>

              <article class="zone">
                  <div class="projet scene">
                  <HashLink to="/scene#top">
                  <img src="_assets/images/scene_img1.png" alt="img_scene"/>
                  </HashLink>
                  </div>
                  <h3><HashLink to="/scene#top">Scène Unity</HashLink></h3>
                </article>

                
                <article class="zone">
                  <div class="projet vaisseau">
                  <HashLink to="/vaisseau#top">
                    <img src="_assets/images/vaisseau_img1.png" alt="img_vaisseau" />
                    </HashLink>
                  </div>
                  <h3><HashLink to="/vaisseau#top">Jeu 2D à défilement vertical</HashLink></h3>
                </article>
              
                <article class="zone">
                  <div class="projet robot">
                  <HashLink to="/robot#top">
                  <img src="_assets/images/robot_img1.png" alt="img_robot" />
                  </HashLink>
                  </div>
                  <h3><HashLink to="/robot#top">Jeu 2D à tuiles</HashLink></h3>
                </article>
                
              {/* <article class="zone">
                  <div class="projet magicien">
                  <HashLink to="/magicien#top">
                  <img src="_assets/images/magicien_img41.jpg" alt="img_magicien"/>
                  </HashLink>
                  </div>
                  <h3><HashLink to="/magicien#top">Île procédurale 3D</HashLink></h3>
                </article> */}
              
                {/* <article class="zone">
                  <div class="projet viking">
                  <HashLink to="/viking#top">
                  <img src="_assets/images/viking_img1.png" alt="img_viking" />
                  </HashLink>
                  </div>
                  <h3><HashLink to="/viking#top">Jeu de plates-formes 2D</HashLink></h3>
                </article> */}
      
              </div>
            </section>
          </main>
    )
}

export default Accueil
