import './App.css';
import { HashLink } from 'react-router-hash-link';
import './App.scss';
// import './Page_projet.scss';
import Vaisseau from './page/Vaisseau';
import Final from './page/Final';
import Robot from './page/Robot';
// import Viking from './page/Viking';
// import Magicien from './page/Magicien';
import Scene from './page/Scene';
import { Route, Switch } from 'react-router-dom';
import React from 'react';
import Accueil from './page/Accueil';


function App() {
  return (
    <div className="App">
      
      <div class="interface"></div>

        <Switch>
          
          <Route exact path="/">
            <nav class="header">
              <a href="#h2_reel">Reel</a>
              <a href="#h2_projet">Projets</a>
              <a href="#h2_contact">Contact</a>
            </nav>
            <Accueil/>
          </Route>

          <Route exact path="/final">
            <nav class="header">
            <HashLink to="/#h2_reel">Reel</HashLink>
            <HashLink to="/#h2_projet">Projets</HashLink>
            <HashLink to="/#h2_contact">Contact</HashLink>
            </nav>
            <Final/>
          </Route>

          <Route exact path="/scene">
            <nav class="header">
            <HashLink to="/#h2_reel">Reel</HashLink>
            <HashLink to="/#h2_projet">Projets</HashLink>
            <HashLink to="/#h2_contact">Contact</HashLink>
            </nav>
            <Scene/>
          </Route>

          <Route exact path="/vaisseau">
          <nav class="header">
            <HashLink to="/#h2_reel">Reel</HashLink>
            <HashLink to="/#h2_projet">Projets</HashLink>
            <HashLink to="/#h2_contact">Contact</HashLink>
        </nav>

            <Vaisseau/>
          </Route>

          <Route exact path="/robot">
          <nav class="header">
          <HashLink to="/#h2_reel">Reel</HashLink>
            <HashLink to="/#h2_projet">Projets</HashLink>
            <HashLink to="/#h2_contact">Contact</HashLink>
        </nav>
            <Robot/>
          </Route>


          {/* <Route exact path="/magicien">
          <nav class="header">
          <HashLink to="/#h2_reel">Reel</HashLink>
            <HashLink to="/#h2_projet">Projets</HashLink>
            <HashLink to="/#h2_contact">Contact</HashLink>
        </nav>
            <Magicien/>
          </Route> */}

          {/* <Route exact path="/viking">
          <nav class="header">
          <HashLink to="/#h2_reel">Reel</HashLink>
            <HashLink to="/#h2_projet">Projets</HashLink>
            <HashLink to="/#h2_contact">Contact</HashLink>
        </nav>
            <Viking/>
          </Route> */}

        </Switch>
  
      <footer id="section_contact">
        <h2 id="h2_contact">Contact</h2>
        <nav class="footer">
            <a href="/">raphael-blais@outlook.com</a>
            <a href="/CV_web.pdf" target="_blank">CV</a>
            <a href="https://www.linkedin.com/in/rapha%C3%ABl-blais-684015225/" target="_blank" rel="noreferrer">Linked In</a>
        </nav>
      </footer>
      
    </div>
  );
}

export default App;
